@font-face {
  font-family: "Albert Sans";
  src: local("Albert Sans"),
    url("./fonts/AlbertSans-VariableFont_wght.ttf") format("truetype");
  font-weight: 100 1000;
}

* {
  box-sizing: border-box;
}

html {
  font-size: 10px;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
