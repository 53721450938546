$background: #f5f0f0;
$background-gradient: radial-gradient(
    at 50% -60%,
    rgba(230, 162, 176, 0.8),
    transparent 20%
  ),
  linear-gradient(215deg, rgba(230, 162, 176, 0.8) 5%, transparent 55%),
  linear-gradient(105deg, rgba(245, 240, 240, 0.8) 20%, rgb(240, 219, 188) 55%),
  linear-gradient(215deg, rgb(240, 219, 188), rgba(245, 240, 240, 0) 80%);
// linear-gradient(195deg, rgba(230, 162, 176, 1), rgba(238, 221, 196, 0.8) 40%);
// linear-gradient(240deg, rgb(230, 162, 176), rgba(238, 221, 196, 0.8) 50%);
$ink: #292929;

.App {
  text-align: left;
}

.ContentWrapper {
  background: $background-gradient;
  background-color: $background;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ContentContainer {
  padding: 10vmin;
  color: white;
  max-width: 750px;

  h1 {
    font-family: "Albert Sans";
    font-weight: 700;
    margin: 0;
    padding-top: 2rem;
    font-size: 8.8rem;
    line-height: 9rem;
    color: $ink;
  }

  p {
    color: $ink;
    font-family: "Albert Sans";
    font-weight: 300;
    font-size: 2.4rem;
    line-height: 4rem;
    margin: 2.4rem 0;
  }
}

.AppLink {
  font-family: "Albert Sans";
  font-weight: 600;
  color: $ink;
  text-decoration: none;
  margin: 0;
  font-size: 2.4rem;
  line-height: 4rem;
  display: inline-block;
  position: relative;

  &:hover,
  :link,
  :visited,
  :active {
    color: $ink;
    &:after {
      content: '';
      position: absolute;
      display: block;
      left: 0;
      top: 0;
      width: 100%;
      height: 80%;
      border-bottom: 1px solid $ink;
      transition-duration: 300ms;
      transition-timing-function: ease-out;
      transition-property: all;
    }
  }

  &:after {
    content: '';
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    width: 0%;
    height: 80%;
    border-bottom: 1px solid $ink;
    transition-duration: 300ms;
    transition-timing-function: ease-out;
    transition-property: all;
  }
}
